<template>
  <v-form ref="form">
    <holders-selection-form
      v-model="holder"
      :title="'Select Holder'"
      :holders-list="holdersList"
      @update="updateHolderId"
    />
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Convertible terms'"
          :rules="{ required: true }"
        >
          <common-input-select
            v-model="formData.convertible_terms_id"
            label="Convertible Terms"
            placeholder="Select convertible terms"
            :items="convertibleTerms"
            item-text="name"
            item-value="id"
            :error-messages="errors"
            @change="updateConvertibles"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Financing round'"
          :rules="{ required: true}"
        >
          <common-input-select
            v-model="formData.financing_round_id"
            label="Financing Round"
            placeholder="Select financing round"
            :items="financingRounds"
            item-text="name"
            item-value="id"
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Invested capital'"
          :rules="{ required: true, min_value: 0 }"
        >
          <common-input-currency-input
            v-model="formData.amount"
            label="Invested capital"
            color="primary"
            :error-messages="errors"
            :options="{ locale: 'en-US', precision: 2 }"
            prepend-inner-icon="mdi-currency-usd"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Stock registration name'"
          :rules="{ required: true }"
        >
          <common-input-text-field
            v-model="stockRegistrationName"
            label="Stock Registration Name"
            color="primary"
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Grant date'"
          :rules="{ required: true }"
        >
          <input-template-date
            v-model="grantDate"
            :mask="'##/##/####'"
            :input-templ="{label: 'Grant date', mandatory: true}"
            :error-messages="errors"
            clearable
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        lg="4"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Maturation date'"
        >
          <input-template-date
            v-model="maturationDate"
            :mask="'##/##/####'"
            :input-templ="{label: 'Maturation Date'}"
            :error-messages="errors"
            clearable
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Board approval date'"
          :rules="{ required: true }"
        >
          <input-template-date
            v-model="boardApprovedOn"
            :mask="'##/##/####'"
            :input-templ="{label: 'Board approval date', mandatory: true}"
            :error-messages="errors"
            clearable
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <notes-and-documents-form
          :id="convertibleId"
          :type="CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLES"
          :is-submit="isSubmit"
          @navToList="$emit('navToList', true)"
        />
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
  import moment from 'moment'
  import InputTemplateDate from '@/components/common/fmsvc/form-templates/InputTemplateDate'
  import HoldersSelectionForm from './HoldersSelectionForm'
  import NotesAndDocumentsForm from './NotesAndDocumentsForm.vue'

  export default {
    name: 'ConvertiblesForm',

    components: {
      HoldersSelectionForm,
      NotesAndDocumentsForm,
      InputTemplateDate,
    },

    props: {
      value: {
        type: Object,
        required: true,
      },
      holder: {
        type: Object,
        required: true,
      },
      convertibleTerms: {
        type: Array,
        required: true,
      },
      financingRounds: {
        type: Array,
        required: true,
      },
      holdersList: {
        type: Array,
        required: true,
      },
      convertibleId: {
        type: String,
      },
      isSubmit: {
        type: Boolean,
      },
    },

    data: () => ({
      notesData: {},
      grantDate: '',
      maturationDate: '',
      boardApprovedOn: '',
      stockRegistrationName: '',
    }),

    computed: {
      formData: {
        get () {
          return this.value || {}
        },
        set (val) {
          this.$emit('input', val)
        },
      },
      selectedConvertibleTerms: {
        get () {
          return this.formData.convertible_terms_id || ''
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    watch: {
      value: {
        handler (val) {
          if (val) {
            this.grantDate = val.issued_on ? moment(val.issued_on).format('MM/DD/YYYY') : ''
            this.maturationDate = val.matures_on ? moment(val.matures_on).format('MM/DD/YYYY') : ''
            this.boardApprovedOn = val.board_approved_on ? moment(val.board_approved_on).format('MM/DD/YYYY') : ''
          }
        },
        immediate: true,
      },
      grantDate: {
        handler (val) {
          if (val) {
            this.formData.issued_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
      maturationDate: {
        handler (val) {
          if (val) {
            this.formData.matures_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
      boardApprovedOn: {
        handler (val) {
          if (val) {
            this.formData.board_approved_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
      selectedConvertibleTerms: {
        handler (val) {
          if (val) {
            const convertibleTerm = this.convertibleTerms.find(term => term.id === val)
            if (convertibleTerm.convertible_type) {
              this.stockRegistrationName = `${this.prefix(convertibleTerm.convertible_type)}-`
            }
            if (convertibleTerm.board_approved_on) {
              this.boardApprovedOn = moment(convertibleTerm.board_approved_on).format('MM/DD/YYYY')
            } else {
              this.boardApprovedOn = ''
            }
          }
        },
        immediate: true,
      },
      stockRegistrationName: {
        handler (val) {
          if (val) {
            this.formData.registration_name = val
          }
        },
        immediate: true,
      },
    },

    methods: {
      updateHolderId (val) {
        this.$emit('updateHolder', val)
      },
      updateConvertibles () {
        const selectedConvertible = this.convertibleTerms.find((w) => { return w.id === this.formData.convertible_terms_id })
        this.formData.share_terms_id = selectedConvertible.share_terms_id
      },
      prefix (type) {
        if (this.type === this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE) {
          return this.CONSTS.SECURITIES_PREFIX.CONVERTIBLE
        } else if (type === this.CONSTS.CONVERTIBLE_TERM_TYPES.DEBT) {
          return this.CONSTS.SECURITIES_PREFIX.DEBT
        } else if (type === this.CONSTS.CONVERTIBLE_TERM_TYPES.SAFE) {
          return this.CONSTS.SECURITIES_PREFIX.SAFE
        }
      },
    },
  }
</script>
