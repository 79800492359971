<template>
  <common-trs-page-container>
    <template v-slot:page-content>
      <common-app-page-header
        :title="'Draft Convertibles'"
      />
      <div class="trs-form-container-card">
        <common-trs-card class="pa-2 pb-0 mb-2">
          <validation-observer
            ref="observer"
            v-slot="{ valid }"
            class="ml-auto"
          >
            <v-card-text class="pa-0">
              <form>
                <convertibles-form
                  v-model="convertiblesData"
                  :convertible-terms="convertibleTerms"
                  :financing-rounds="financingRounds"
                  :holders-list="investors"
                  :holder="holder"
                  :convertible-id="convertibleId"
                  :is-submit="isSubmit"
                  @updateHolder="updateHolder"
                  @navToList="navToList"
                />
              </form>
            </v-card-text>
            <v-divider />
            <v-row class="d-flex justify-space-between pa-5">
              <v-col>
                <common-trs-btn
                  type="secondary"
                  medium
                  class="pull-right mr-2"
                  text
                  :to="{name: 'DraftSecurities', query: { tab: 2 }}"
                >
                  Cancel
                </common-trs-btn>
              </v-col>
              <v-col class="text-right">
                <common-trs-btn
                  type="primary"
                  medium
                  class="white--text"
                  text
                  :disabled="!valid"
                  :loading="loading"
                  @click="save"
                >
                  Save
                </common-trs-btn>
              </v-col>
            </v-row>
          </validation-observer>
        </common-trs-card>
      </div>
    </template>
  </common-trs-page-container>
</template>

<script>
  import ConvertiblesForm from '@/components/common/captable/components/forms/ConvertiblesForm'
  import * as captableService from '@/components/common/captable/services/captable'

  export default {
    name: 'DraftConvertibles',

    components: {
      ConvertiblesForm,
    },

    data: () => ({
      convertibleTerms: [],
      financingRounds: [],
      investors: [],
      loading: false,
      convertiblesData: {},
      holder: {},
      convertibleId: '',
      isSubmit: false,
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },

    watch: {
      internalDialog: {
        handler (val) {
          if (val) {
            this.isSubmit = false
          }
        },
      },
    },

    mounted () {
      this.loadAllConvertibleTerms()
      this.loadIssuerProfileList()
      this.loadFinancingRoundList()
    },

    methods: {
      async save () {
        this.loading = true
        if (!this.convertiblesData.holder_id) {
          this.convertiblesData.holder_id = await this.createNewInvestor()
        }
        if (this.convertiblesData.holder_id) {
          try {
            const resp = await captableService.draftConvertibles(this.captableId, this.convertiblesData)
            if (resp.data.id) {
              this.convertibleId = resp.data.id
              this.isSubmit = true
            }
          } catch (err) {
            this.loading = false
            this.$store.dispatch('app/handleError', {
              err,
              message: 'Sorry! Unable to draft convertible. Please try again later.',
            })
          }
        }
      },

      navToList () {
        this.loading = false
        this.$store.dispatch('app/message', { text: 'Convertible drafted successfully.' })
        this.$router.push({ name: 'DraftSecurities', query: { tab: 2 } })
      },

      async loadAllConvertibleTerms () {
        const resp = await captableService.getConvertibleTermList(this.captableId)
        if (resp && resp.data) {
          this.convertibleTerms = resp.data.convertible_terms || []
        }
      },

      async loadIssuerProfileList () {
        try {
          const resp = await captableService.getIssuerProfileList(this.captableId)
          this.investors = resp.data.profiles || []
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
      },

      async loadFinancingRoundList () {
        try {
          const resp = await captableService.getAllFinancingRounds(this.captableId)
          if (resp && resp.data) {
            this.financingRounds = resp.data.financing_rounds || []
          }
        } catch (e) {}
      },

      async createNewInvestor () {
        try {
          const resp = await captableService.draftProfile(this.captableId, this.holder)
          const id = resp && resp.data.id
          await captableService.publishProfile(this.captableId, id)
          return id
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: `Sorry! Unable to draft convertible for investor ${this.holder.name}. Please try again later.`,
          })
        }
      },

      updateHolder (val) {
        if (val.to_holder_id) {
          this.convertiblesData.holder_id = val.to_holder_id
        } else {
          this.convertiblesData.holder_id = null
          delete val.to_holder_id
          this.holder = val
        }
      },
    },
  }
</script>
