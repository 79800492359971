<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        class="trsText--text font-weight-medium text-h4 py-0"
      >
        {{ title }}
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <v-radio-group
          v-model="userType"
          column
          @change="update"
        >
          <v-radio
            v-for="(item, key) of CONSTS.USER_TYPES"
            :key="key"
            :label="key + ' Holder'"
            color="primary"
            :value="item"
          />
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row
     class="mt-0"
     v-if="userType == CONSTS.USER_TYPES.Existing"
     >
      <v-col
        cols="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Holder'"
          :rules="{ required: true }"
        >
          <common-input-select
            v-model="value.to_holder_id"
            label="Holder"
            placeholder="Select holder profile"
            :items="holdersList"
            item-text="name"
            item-value="id"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            @change="update"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        cols="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Holder name'"
          :rules="{ required: true, max: 255 }"
        >
          <common-input-text-field
            v-model="value.name"
            label="Holder Name"
            color="primary"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            @input="update"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Holder email'"
          :rules="{ required: true, max: 255, email: true }"
        >
          <common-input-text-field
            v-model="value.email"
            label="Holder Email"
            color="primary"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            @input="update"
          />
        </validation-provider>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  export default {
    name: 'HoldersSelectionForm',

    props: {
      value: {
        type: Object,
        default: () => {},
      },
      title: {
        type: String,
        required: true,
      },
      holdersList: {
        type: Array,
        required: true,
        default: () => [],
      },
    },

    data: () => ({
      userType: '',
    }),

    created () {
      this.userType = this.CONSTS.USER_TYPES.Existing
    },

    methods: {
      update () {
        if (this.userType !== this.CONSTS.USER_TYPES.Existing) {
          this.value.to_holder_id = null
        }
        this.$emit('update', this.value)
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep .v-input--radio-group__input {
    flex-direction: row !important;
  }
  ::v-deep .v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
    margin-bottom: 0 !important;
    margin-right: 20px;
  }
</style>
